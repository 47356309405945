import { hydrate } from "react-dom";
import { RemixBrowser } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { dsn, tunnel } from "./sentry-config";
import { useEffect } from "react";
import { useLocation, useMatches } from "@remix-run/react";

// @ts-ignore
console.log(window?.configurations?.sentry_release);

Sentry.init({
  dsn,
  tunnel,
  tracesSampleRate: 1,
  // @ts-ignore
  release: window?.configurations?.sentry_release,
  // @ts-ignore
  environment: window?.configurations?.environment,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches
      ),
    }),
  ],
});

hydrate(<RemixBrowser />, document);
